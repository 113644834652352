<template>
  <div v-loading="loading" class="main">
    <template v-if="templateModel.id">
      <div class="template_info">
        <div class="template_row">
          <p class="name">
            <span style="display: inline-block">当前模板：</span>
            <span class="name-text-oveflow">{{ templateModel.name }}</span>
          </p>
          <div class="template-priview">
            <img :src="templateModel.cover+'&imageView2/2/w/256/h/256'" class="picture" alt="" title="更换模板" @click="chooseTemplate">
            <div class="template_row template_priview-btn" v-rights="'athena:design:edit'">
              <span style="float:right;color:#409EFF;cursor:pointer;" title="更换模板" @click="chooseTemplate">
                <GIcon icon="icon-genghuan1" />更换模板
                </span>
            </div>
          </div>
        </div>

        <el-button
          icon="el-icon-magic-stick"
          type="text"
          size="mini"
          class="btn_view"
          @click="previewPage(true)"
        >
          预览当前页
        </el-button>
      </div>
      <div class="form_box">
        <c-form :config="componentList" :content="formData" :form-show="true" @submit="saveForm"></c-form>
      </div>
    </template>
    <template v-else>
      <div class="empty_box" @click="chooseTemplate">
        <i class="el-icon-plus"></i>
        <span>选择页面模板</span>
      </div>
    </template>
    <ChooseTemplate ref="chooseTemplate" @submit="getTemplateInfo"></ChooseTemplate>
  </div>
</template>

<script>
import ChooseTemplate from './ChooseTemplate'
import { Dropdown, DropdownMenu } from 'element-ui'
import { mapGetters } from 'vuex'

export default {
  name: 'PageTemplate',
  components: {
    ChooseTemplate,
    'el-dropdown': Dropdown,
    'el-dropdown-menu': DropdownMenu },
  props: {
    data: {
      type: Object,
      default() {
        return {
          form: {}
        }
      }
    }
  },
  data() {
    return {
      templateModel: {},
      formData: {},
      loading: false
    }
  },
  computed: {
    ...mapGetters(['userRights']),
    componentList() {
      const { components } = this.templateModel
      return {
        'name': '首页设置',
        'desc': '您可以在此页面完成首页设置',
        'submitBtnShow': true,
        components
      }
    },
    companyInfo() {
      return this.$store.state.companyInfo
    },
    equipInfo() {
      return this.$store.state.equipInfo
    },
    templateInfo() {
      return this.$store.state.template.templateInfo
    }
  },
  watch: {
    data: {
      deep: true,
      handler() {
        this.templateModel = {}
        this.formData = {}
        this.getTemplateInfo()
      }
    }
  },
  created() {
    this.getTemplateInfo()
  },
  methods: {
    previewPage(flag) {
      this.$emit('previewPage', flag)
    },
    async getTemplateInfo() {
      const { id } = this.data
      if (!id) return false
      const { id: deviceId } = this.equipInfo
      const { companyId } = this.companyInfo
      const param = { id, deviceId, companyId }
      this.loading = true
      const response = await this.$axios.get('/athena/page/detail', param)
      this.loading = false
      this.templateModel = response.data.template || {}
      const dataConfig = response.data.data && response.data.data.dataConfig
      this.formData = dataConfig || {}
      this.$store.commit('setTemplateState', {
        type: 'templateInfo',
        data: response.data
      })
      this.$emit('update:reviewInfo', response.data.preview)
    },
    chooseTemplate() {
      if (this.userRights.get('athena:design:edit')) {
        this.$refs.chooseTemplate.show(this.data)
      }
    },
    async saveForm(data) {
      const { id } = this.data
      const { id: templateId } = this.templateInfo.template
      const param = {
        id,
        templateId,
        data: JSON.stringify(data.form),
        medias: JSON.stringify(data.medias)
      }
      const response = await this.$axios.post('/athena/page/saveData', param)
      if (response.code === '0000') {
        this.$message.success('保存成功')
        this.getTemplateInfo()
      }
    }
  }
}
</script>

<style scoped lang="scss">
.main {
  height: 100%;
  border: 1px solid transparent;
  transition: all 0.2s;
  .template_info {
    float: left;
    width: 240px;
    padding: 10px;
    font-size: 14px;
    .template-priview{
      position: relative;
      .template_priview-btn{
        position: absolute;
        right: 10px;
        top: 10px;
        font-size: 12px;
      }
    }
    .template_row {
      .name {
        color: #878a92;
        margin-bottom: 10px;
        font-size: 14px;
        display: flex;
        align-items: center;
        .name-text-oveflow{
          display: inline-block;
          width: calc(100% - 74px);
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
          font-size: 14px;
        }
      }
      .picture {
        display: block;
        width: 100%;
        min-height: 50px;
        cursor: pointer;
        border: 1px solid #ccc;
      }
    }
    .btn_view{
      float: right;
      font-size: 14px;
      ::v-deep{
        .el-icon-magic-stick{
          font-size: 16px;
          vertical-align: text-bottom;
        }
        span{
          margin: 0;
        }
      }
    }
  }
  .form_box {
    float: left;
    width: calc(100% - 240px);
    height: 100%;
    border-left: 1px solid #e5e9f2;
    overflow: auto;
  }
  .empty_box {
    width: 300px;
    height: 200px;
    border: 1px dashed #ccc;
    border-radius: 4px;
    line-height: 200px;
    margin: 100px auto;
    text-align: center;
    cursor: pointer;
    font-size: 20px;
  }
}
</style>
