<!--页面管理-->
<template>
  <div class="design_main clearfix">
    <div class="tree_box">

      <div class="depart-tree">
        <el-input
          v-model="queryKey"
          class="input-search"
          placeholder="输入页面名称搜索"
          enter-button="搜索"
          allow-clear
          size="small"
          clearable
        >
          <el-button slot="append" size="small" type="primary" @click="getTreeData">搜 索</el-button>
        </el-input>
        <Spin v-if="queryLoad" class="depart-tree-spin" />
        <div class="head-editor">
          <p>页面</p>
          <div>
            <el-button v-rights="'athena:design:add'" size="small" type="text" @click="append">新增</el-button>
            <el-button v-rights="'athena:design:edit'" size="small" type="text" @click="edit">编辑</el-button>
            <el-button v-rights="'athena:design:delete'" size="small" type="text" @click="remove">删除</el-button>
          </div>
        </div>
        <GTree
            :tree-data="treeData"
            :default-expanded-keys="defaultExpanded"
            :default-selected-keys="defaultExpanded[0]"
            :replace-fields="{
              key: 'id',
              value: 'id',
              label: 'pageName',
              children: 'sub'
            }"
            @select="selectPage"
        />
      </div>

    </div>
    <div v-if="currentPage.id" class="page_info">
      <div class="template_box custom-template_box">
        <PageTemplate :data="currentPage" :review-info.sync="reviewInfo" @previewPage="flag => previewPage(flag)" />
      </div>
    </div>

    <Modal
      :visible="isShowDesign"
      class-name="depart-tree-model"
      :title="designTitle"
      :confirm-loading="modalLoad"
      ok-text="保存"
      cancel-text="取消"
      destroy-on-close
      @ok="handleOk"
      @cancel="handleCancel"
    >
      <a-form-model ref="departForm" class="global-card-form" :model="designForm" :rules="designRules">
        <a-form-model-item label="页面名称" prop="pageName">
          <a-input v-model="designForm.pageName" size="large" placeholder="请输入页面名称" />
        </a-form-model-item>
      </a-form-model>
    </Modal>

  </div>
</template>

<script>
import PageTemplate from '@/views/components/PageTemplate'
import GTree from '@/components/GTree'
import { Spin, Modal } from 'ant-design-vue'

export default {
  name: 'Design',
  components: {
    PageTemplate,
    Spin,
    Modal,
    GTree
  },
  data() {
    return {
      designForm: {
        pageName: '',
        isEdit: false
      },
      designRules: {
        pageName: [{ required: true, message: '请输入页面名称' }]
      },
      isShowDesign: false,
      modalLoad: false,
      queryLoad: false,
      designTitle: '',
      queryKey: '',
      treeData: [],
      defaultExpanded: [],
      currentPage: {},
      reviewInfo: {}
    }
  },
  computed: {
    companyInfo() {
      return this.$store.getters.baseAppInfo
    },
    equipInfo() {
      return this.$store.getters.equipInfo
    }
  },
  created() {
    const { page } = this.$route.query
    if (page) {
      sessionStorage.setItem('currentPage', JSON.stringify({ id: Number(page) }))
    }
    this.$router.push({
      query: {}
    })
    this.getTreeData()
  },
  beforeDestroy() {
    sessionStorage.setItem('currentPage', JSON.stringify(''))
  },
  methods: {
    handleOk() {
      this.$refs.departForm.validate(valida => {
        if (valida) {
          if (this.designForm.isEdit) {
            this.editAPI()
          } else {
           this.addAPI()
          }
        }
      })
    },
    addAPI() {
      this.modalLoad = true
      this.$axios.post('/athena/page/add', {
        ...this.designForm,
        parentId: this.currentPage.id
      }).then(res => {
        this.modalLoad = false
        if (res.code !== '0000') return
        this.$message.success('新增成功')
        this.getTreeData()
        this.handleCancel()
      }).catch((res) => {
        this.modalLoad = false
        this.$message.error(res.data.msg)
      })
    },
    handleCancel() {
      this.designForm.pageName = ''
      this.isShowDesign = false
    },
    async getTreeData() {
      const { id: deviceId } = this.equipInfo
      const { companyId } = this.companyInfo
      this.queryLoad = true
      const response = await this.$axios.get('/athena/page/tree', { companyId, deviceId, queryKey: this.queryKey })
      if (response.code === '0000') {
        this.treeData = [response.data]
        let selectPage = response.data
        const sessionPage = sessionStorage.getItem('currentPage')
        if (sessionPage && JSON.parse(sessionPage).id) {
          selectPage = JSON.parse(sessionPage)
        }
        this.defaultExpanded = [selectPage.id]
        this.currentPage = selectPage
        this.queryLoad = false
        this.$nextTick(() => {
          this.setTreeSelect(selectPage)
        })
      } else {
        this.queryLoad = false
      }
    },
    setTreeSelect(data) {
      this.selectPage(data)
    },
    edit() {
      this.designForm.pageName = this.currentPage.pageName
      this.designForm.isEdit = true
      this.designTitle = '编辑页面'
      this.isShowDesign = true
    },
    editAPI() {
      const { id: deviceId } = this.equipInfo
      const { companyId } = this.companyInfo
      const { id } = this.currentPage
      const param = { pageName: this.designForm.pageName, id, companyId, deviceId }
      this.$axios.post('/athena/page/editName', param).then(response => {
        if (response.code === '0000') {
          this.$message.success('修改成功')
          if (response.code === '0000') {
            this.getTreeData()
            this.handleCancel()
          }
        }
      })
    },
    async append() {
      this.designForm.isEdit = false
      this.designTitle = '新增页面'
      this.isShowDesign = true
    },
    remove() {
      const self = this
      this.$antConfirm({
        title: '删除页面',
        content: '删除后，页面及其数据均被删除，无法恢复。\n 是否确定删除此页面？',
        width: '500px',
        okText: '删除',
        okType: 'danger',
        cancelText: '取消',
        onOk() {
          const { id } = self.currentPage
          self.$axios.post('/athena/page/delete', { id }).then(response => {
            if (response.code === '0000') {
              sessionStorage.setItem('currentPage', JSON.stringify({}))
              self.getTreeData()
            }
          })
        },
        onCancel() {}
      })
    },
    selectPage(data) {
      this.currentPage = data
      this.$store.commit('setTemplateState', {
        type: 'pageInfo',
        data
      })
      sessionStorage.setItem('currentPage', JSON.stringify(data))
    },
    previewPage(newPage) {
      if (newPage) {
        window.open(this.reviewInfo.previewUrl)
      } else {
        window.location.href = this.reviewInfo.previewUrl
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .design_main {
    height: 100%;
    .tree_box {
      float: left;
      width: 260px;
      height: calc(100% - 18px);
      overflow: auto;
      margin: 10px 0 0 10px;
      background: #FFF;
      border-radius: 4px;
      .head-editor{
        width: calc(100% - 16px);
        border-radius: 4px;
        display: flex;
        align-items: center;
        color: $title1;
        background: $themeBg;
        font-size: 14px;
        font-weight: 600;
        padding: 6px 0 6px 12px;
        &>div{
          margin-left: auto;
          ::v-deep{
            .el-button{
              padding: 0 7px;
              margin: 0;
              font-size: 14px;
              font-weight: 600;
            }
          }
        }
      }
      .depart-tree {
        width: 260px;
        height: 100%;
        overflow-y: auto;
        background: #fff;
        box-shadow: var(--cardShadow);
        border-radius: 4px;
        padding: 16px 0 0 16px;
        ::v-deep{
          .el-input{
            padding-right: 16px;
          }
        }
        .g-tree{
          padding-right: 16px;
          height: calc(100% - 78px);
          overflow-y: auto;
        }
        position: relative;
        .depart-tree-spin{
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
          background: rgba(255, 255, 255, .8);
          display: flex;
          align-items: center;
          justify-content: center;
          z-index: 20;
        }
        .input-search{
          margin-bottom: 12px;
        }
        ::v-deep{
          .el-input-group__append{
            .el-button--primary{
              height: 32px;
              background: #409EFF;
              border-color: #409EFF;
              color: #fff;
              border-top-left-radius: 0;
              border-bottom-left-radius: 0;
            }
          }
        }
        .depart-tree-spin{
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
        }
      }
      .tree_node {
        font-size: 14px;
        line-height: 40px;
        font-weight: 400;
        &>span{
          margin-right: 12px;
        }
        .tree_button {
          width: 18px;
          height: 18px;
          border-radius: 2px;
          font-size: 10px;
          margin-left: 0;
          border: 1px solid #606266;
          color: #606266;
          padding: 0;
          line-height: 16px;
          margin-right: 12px;
          ::v-deep{
            i{
              font-weight: bold;
            }
          }
        }
        :hover{
          background: #5482ee;
          border-color: #fff;
          color: #fff;
        }
      }
      ::v-deep .is-current > .el-tree-node__content {
        background: #5482ee;
        color: #fff;
      }
      ::v-deep .el-tree-node__content {
        height: 40px;
      }
    }
    .page_info {
      float: left;
      width: calc(100% - 292px);
      height: 100%;
      margin: 0 10px;
      padding-top: 10px;
      .page_head {
        border-radius: 4px;
        background: #FFF;
        line-height: 40px;
        padding: 0 10px;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .name {
          font-size: 14px;
          font-weight: 500;
        }
        .edit_input {
          width: 200px;
          margin-right: 10px;
        }
        .operate {
          padding: 4px;
          font-size: 14px;
          border-radius: 4px;
          margin-left: 10px;
          ::v-deep{
            .el-icon-check{
              font-weight: bold;
            }
            .el-icon-close{
              font-weight: bold;
            }
          }
        }
      }
      .custom-template_box {
        height: calc(100% - 8px);
        background: #FFF;
        border-radius: 4px;
        ::v-deep{
          .form_box{
            position: relative;
            &>div{
              height: calc(100% - 60px);
              overflow-y: auto;
              .c-form{
                padding-bottom: 0;
                padding-right: 0;
                .form_ul{
                  padding-right: 12px;
                }
              }
            }
          }
          .submit_box{
            position: absolute;
            width: 100%;
            left: 0;
            bottom: 0;
            text-align: center;
            border-top: 1px #ccc solid;
            padding: 10px;
            .el-button{
              width: 180px;
            }
          }
        }
      }
    }
  }
  .btn_view {
    margin: 10px 6px;
  }
</style>
